<template>
  <b-container class="main-content-container px-4 pb-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="4">
        <span class="text-uppercase page-subtitle">Countries</span>
        <h3 class="page-title">Countries Management</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8"></b-col>
      <b-col cols="4">
        <b-input v-model="filter"></b-input>
      </b-col>
    </b-row>
    <b-table bordered hover small :items="tableData" :fields="columns" :filter="filter">
      <template #cell(priceBlocked)="{item}">
        {{ item.priceBlocked ? 'YES' : 'NO' }}
      </template>
      <template #cell(currencyPrice)="{item}">
        {{ item.currencyPrice }}
      </template>
      <template #cell(edit)="{item}">
        <b-button variant="primary" size="sm" @click="edit(item, item.index)"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  content="'Edit name, price exchange or factor'">
          <i class="material-icons">edit</i>
        </b-button>
        <b-button variant="primary" size="sm" @click="viewLogs(item, item.index)"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  content="'View history currency'">
          <i class="material-icons">visibility</i>
        </b-button>
        <b-button variant="primary" size="sm" @click="$refs.edit.open(item)"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  content="'Edit Stages visibility'">
          <i class="material-icons">checklist</i>
        </b-button>
      </template>
    </b-table>
    <b-modal ref="modal" title="Edit country">
      <div class="form-group">
        <label>Name</label>
        <b-input disabled v-model="model.name"/>
      </div>
      <hr>
      <div class="form-group">
        <span class="text-danger">If you update this information. You will need to check the currency price and market factor (shown below) for the information to be correct.</span>
        <br>
        <label>Currency Code</label>
        <b-input disabled v-model="model.currencyCode" placeholder="Currency Code"></b-input>
        <label>Currency Name</label>
        <b-input disabled v-model="model.currencyName" placeholder="Currency Name"></b-input>
      </div>
      <hr>
      <div class="form-group">
        <label>Price 1€ = </label>
        <span class="text-danger"> (Decimals use dot separator (Example= 0.8695) )</span>
        <b-input @keyup="validateNumber($event.target.value)" v-model="model.currencyPrice"/>
        <span v-if="showErrorComma" class="text-danger">Only numbers. Use dot separator for decimals</span>
      </div>
      <div class="form-group">
        <b-checkbox v-model="model.priceBlocked">Block.</b-checkbox>
        <small class="text-muted">If you blocked the price, it will not be exchanged for exchange service.</small>
      </div>
      <div class="form-group">
        <div>Market factor <strong class="pull-right text-danger">({{ model.marketFactor }})</strong></div>
        <b-form-input type="range" min="0.01" max="2" step=".01" v-model="model.marketFactor"/>
      </div>

      <template #modal-footer>
        <b-button variant="danger" @click="$refs.modal.hide()">Cancel</b-button>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
    <b-modal ref="modal_logs" title="List updates" hide-footer size="lg">
      <div style="width: 100%" class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>BEFORE</th>
            <th>AFTER</th>
            <th>DATE</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in arrayLogs" :key="index">
            <td>
              <table class="table table-sm table-bordered">
                <tr>
                  <td>Currency code:</td>
                  <td>{{ item.before['currencyCode'] }}</td>
                </tr>
                <tr>
                  <td>Currency name:</td>
                  <td>{{ item.before['currencyName'] }}</td>
                </tr>
                <tr>
                  <td>Currency symbol:</td>
                  <td>{{ item.before['currencySymbol'] }}</td>
                </tr>
                <tr>
                  <td>Market factor:</td>
                  <td>{{ item.before['marketFactor'] }}</td>
                </tr>
              </table>
            </td>
            <td>
              <table class="table table-sm table-bordered">
                <tr>
                  <td>Currency code:</td>
                  <td>{{ item.after['currencyCode'] }}</td>
                </tr>
                <tr>
                  <td>Currency name:</td>
                  <td>{{ item.after['currencyName'] }}</td>
                </tr>
                <tr>
                  <td>Currency symbol:</td>
                  <td>{{ item.after['currencySymbol'] }}</td>
                </tr>
                <tr>
                  <td>Market factor:</td>
                  <td>{{ item.after['marketFactor'] }}</td>
                </tr>
              </table>
            </td>
            <td>{{ item.date|localDateShort }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>

    <edit-stage-visibility ref="edit"/>
  </b-container>
</template>

<script>
import {get, post} from '@/services/api';
import EditStageVisibility from "@/views/dashboard/countries/EditStageVisibility";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'CountriesList',
  components: {EditStageVisibility},
  mixins: [show_alert_mixin],
  data: () => ({
    tableData: [],
    filter: null,
    columns: [
      {label: 'Name', key: 'name', sortable: true},
      {label: 'Native Name', key: 'nativeName', sortable: true},
      {label: 'Currency Code', key: 'currencyCode', sortable: true},
      {label: 'Currency Name', key: 'currencyName', sortable: true},
      {label: 'Currency Symbol', key: 'currencySymbol', sortable: true},
      {label: 'Price Fixed', key: 'priceBlocked', sortable: true},
      {label: 'Currency Price', key: 'currencyPrice', sortable: true},
      {label: 'Market Factor', key: 'marketFactor', sortable: true},
      {label: 'Edit', key: 'edit', sortable: true}],
    model: {},
    index: null,
    showErrorComma: false,
    arrayLogs: []
  }),
  mounted() {
    this.load();
  },
  methods: {
    load() {
      get('geo/countries', null, true)
        .then(({data}) => {
          this.tableData = data;
        });
    },
    change(item) {
      this.$swal({
        title: 'Change market factor to: ' + item.name,
        text: 'Info: this factor is for shipper country 1€ = configured factor',
        icon: 'question',
        input: 'range',
        inputAttributes: {
          min: 0.00,
          max: 2,
          step: 0.01
        },
        inputValue: item.marketFactor,
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      })
        .then(el => {
          if (el.isConfirmed) {
            this.$store.commit('loaderManager', false);
            post('geo/set-market-factor', {
              country_id: item.id,
              marketFactor: el.value
            })
              .then(() => {
                item.marketFactor = marketFactor;
                this.load()
                this.$swal({
                  confirmButtonColor: '#d14343',
                  showConfirmButton: true,
                  showCancelButton: false,
                  title: 'success'
                });
              })
              .finally(() => this.$store.commit('loaderManager', false));
          }
        });
    },
    edit(item, index) {
      this.model = item;
      this.index = index;
      this.$refs.modal.show();
    },
    save() {
      try {
        if (this.model.currencyPrice !== null && this.model.currencyPrice !== '') {
          const value = (this.model.currencyPrice.toString().includes(',')) ? this.model.currencyPrice.toString().replace(',', '.') : this.model.currencyPrice
          post(`geo/update/${this.model.id}`, {
            ...this.model, currencyPrice: Number(value)
          }, true)
            .then((res) => {
              this.$swal({
                confirmButtonColor: '#d14343',
                cancelButtonColor: '#000000',
                title: 'Success',
                icon: 'success',
                timer: 1500,
              });
              this.model = res.data;
              this.tableData = this.tableData.map(el => {
                if (el.id === res.data.id) {
                  return res.data;
                }
                return el;
              });
              this.$refs.modal.hide();
            });
        } else {
          this.showAlertNotification('All fields are required', 'error')
        }
      } catch (e) {

      }
    },
    validateNumber(value) {
      let regex = /^(\d+\.?|\d*\.\d+)$/;
      this.showErrorComma = !regex.test(value);
    },
    viewLogs(row, index) {
      this.arrayLogs = []
      get(`geo/currency-logs/${row.id}`, null, true)
        .then(({data}) => {
          this.$refs.modal_logs.show();
          this.arrayLogs = data
        });
    }
  },
};
</script>

<style scoped>

</style>
