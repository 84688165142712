<template>
  <b-container class="main-content-container px-4 pb-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-sm-0" col sm="12">
        <span class="text-uppercase page-subtitle">Price policy</span>
        <h3 class="page-title">Configuration</h3>
      </b-col>
    </b-row>
    <b-card>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <b-form-group>
            <label>Minimum order price (upgrade or modification fee)</label>
            <b-form-input type="number" v-model.number="model.minimum_price"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <b-form-group>
            <label>Additional discount on retail pricing for existing customers after two year</label>
            <b-form-input type="number" min="0" max="1" step="0.01"
                          v-model.number="model.discount_after_a_year"></b-form-input>
            -{{ model.discount_after_a_year }} %
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12"></div>
        <div class="col-lg-4 col-md-4 col-sm-12 justify-content-center">
          <b-button variant="primary" class="btn-block" @click="save">SAVE INFORMATION</b-button>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12"></div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import {get, post} from '@/services/api';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'PricePolicyDiscount',
  mixins: [show_alert_mixin],
  data: () => ({
    model: {
      minimum_price: null,
      discount_after_a_year: null,
    }
  }),
  methods: {
    load() {
      get('common/price-policy')
        .then(({data}) => {
          if (data) {
            this.model = data.data;
            this.model.discount_after_a_year = data.data.discount_after_a_year * 100
          }
        });
    },
    save() {
      post('common/put-price-policy', {
        discount_after_a_year: this.model.discount_after_a_year / 100,
        minimum_price: this.model.minimum_price
      }, true).then(() => {
        this.showAlertNotification("Success")
      });
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style scoped>

</style>
