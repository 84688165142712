<template>
  <div style="width: 100%; margin-top: 0.5vw">
    <b-nav fill>
      <b-nav-item active>
        <span class="title" :class="{ 'selected': showCountries }" @click="selectTab('countries')">Country Management</span>
      </b-nav-item>
      <b-nav-item><span class="title" :class="{ 'selected': showPolicy }" @click="selectTab('policy')">Price Policy</span>
      </b-nav-item>
    </b-nav>
    <Countries v-show="showCountries"/>
    <Price v-show="showPolicy"/>
  </div>
</template>

<script>

import Countries from '@/views/dashboard/countries/CountriesList';
import Price from '@/views/dashboard/price-policy-discount/PricePolicyDiscount';

export default {
  name: 'DashboardCredits',
  components: {
    Countries,
    Price
  },
  data: () => ({
    showCountries: true,
    showPolicy: false,
  }),
  methods: {
    selectTab(type) {
      if (type.toString() === 'countries') {
        this.showCountries = true;
        this.showPolicy = false;
      } else if (type.toString() === 'policy') {
        this.showCountries = false;
        this.showPolicy = true;
      }
    }
  }
};
</script>

<style scoped>

.selected {
  background: black;
  padding: 0.3vw;
  color: white !important;
  border: 0 !important;
}

.title {
  border: 1px solid red;
  padding: 0.5vw;
  color: #d14343;
  font-weight: bold;
  font-size: 1vw
}

</style>
