<template>
  <b-modal v-if="country" :title="`Edit stages visibility - ${country.name}`" v-model="active" @ok="save" @close="active = false">
    <b-row>
      <b-col>
        <h3 class="text-center">ECU</h3>
        <b-checkbox v-for="stage of ecu" v-model="stage.status">
          {{ stage.name }}
        </b-checkbox>
      </b-col>
      <b-col>
        <h3 class="text-center">TCU/DSG</h3>
        <b-checkbox v-for="stage of tcu" v-model="stage.status">
          {{ stage.name }}
        </b-checkbox>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {get, post} from "@/services/api";

export default {
  name: "EditStageVisibility",
  data: () => ({
    active: false,
    country: null,
    ecu: [
      {
        id: '1',
        name: 'Stage I',
        status: true
      },
      {
        id: '2',
        name: 'Stage II',
        status: true
      },
      {
        id: '2.1',
        name: 'Stage II+',
        status: true
      },
      {
        id: '3',
        name: 'Stage III',
        status: true
      },
      {
        id: '4',
        name: 'Stage IV',
        status: true
      }
    ],
    tcu: [
      {
        id: 'original_file',
        name: 'Original File',
        status: true
      },
      {
        id: 'Stock',
        name: 'Drivability',
        status: true
      },
      {
        id: '1',
        name: 'Stage I',
        status: true
      },
      {
        id: '2',
        name: 'Stage II',
        status: true
      },
      {
        id: '2.1',
        name: 'Stage II+',
        status: true
      },
      {
        id: '3',
        name: 'Stage III',
        status: true
      },
      {
        id: '4',
        name: 'Stage IV',
        status: true
      }
    ]
  }),
  methods: {
    open(country) {
      this.active = true;
      this.country = country;
      this.load()
    },
    load() {
      get(`geo/stage-visibility/${this.country.id}`, null, true)
        .then(({data}) => {
          if (data) {
            this.ecu = data.ecu;
            this.tcu = data.tcu;
          }
        })
    },
    save() {
      this.$store.commit('loaderManager', true);
      post(`geo/stage-visibility/${this.country.id}`,
        {country_id: this.country.id, ecu: this.ecu, tcu: this.tcu})
        .then(() => {
          this.$store.commit('loaderManager', false)
        })
    }
  }
}
</script>

<style scoped>

</style>
